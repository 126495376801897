@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/App.css */
@font-face {
  font-family: "Circular Std";
  src: url("../public/fonts/CircularStd-Book.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Circular Std";
  src: url("../public/fonts/CircularStd-Bold.otf") format("truetype");
  font-weight: bold;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: "Circular Std", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "ss04" on !important;

  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Circular Std" !important;
  font-feature-settings: "ss04" on !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

aside {
  min-width: 260px !important;
}
.ant-layout {
  background-color: #f5f5fc !important;
}

.ant-table-placeholder {
  z-index: 10 !important;
}
.ant-menu-title-content {
  margin-left: 10px;
}

.ant-table-wrapper {
  background-color: white;
  border-radius: 10px;
}
